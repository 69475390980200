html,
body {
    padding: 0;
    margin: 0;
    font-family: $inter;
    color: $c-txt;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    background-color: $c-white;

}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


.hidden {
  display: none;
}


strong {
    font-family: $inter;
    font-weight: 900;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $inter;
    font-weight: 700;
}

h1 {
    font-size: 3.875rem;
    line-height: 4.6875rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* KONTAIERY */

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    @media only screen and (min-width: 640px) {
        padding-right: 16px;
        padding-left: 16px;
    }
    @media only screen and (min-width: 768px) {
        padding-right: 24px;
        padding-left: 24px;
    }
    @media only screen and (min-width: 990px) {
        padding-right: 36px;
        padding-left: 36px;
    }
    @media only screen and (min-width: 1024px) {
        padding-right: 48px;
        padding-left: 48px;
    }
    @media only screen and (min-width: 1376px) {
        max-width: 1376px;
        padding-right: 48px;
        padding-left: 48px;
    }
}

.container__noPadd {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (min-width: 1366px) {
        max-width: 1280px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.container__basicPad {
    padding-top: 3 * $p-basic;
    padding-bottom: 5 * $p-basic;
    @media only screen and (max-width: 768px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 4 * $p-basic;
    }
    @media only screen and (max-width: 640px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}



/* CLEARFIX */

.cf:before,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.mb-6 {
    margin-bottom: 6 * $p-basic;
}

.mb-4 {
    margin-bottom: 4 * $p-basic;
}

.mb-3 {
    margin-bottom: 3 * $p-basic;
}

.mb-2 {
    margin-bottom: 2 * $p-basic;
}

.mt-6 {
    margin-top: 6 * $p-basic;
}

.mt-4 {
    margin-top: 4 * $p-basic;
}

.mt-3 {
    margin-top: 3 * $p-basic;
}

.mt-2 {
    margin-top: 2 * $p-basic;
}

.ta-center {
    text-align: center;
}



/* MODAL */

.modal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($c-grey, 0.40);
    display: flex;
    align-items: center;
    align-content: center;
}

.modal__body {
    width: 100%;
    max-width: 700px;
    background-color: rgba($c-green, 0.75);
    margin: (3 * $p-basic) auto (3 * $p-basic) auto;
    padding: 15px 30px 30px 30px;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 560px) {
        padding: 12px 10px 10px 10px;
    }
    position: relative;
}

.modal__close {
    width: 100%;
    height: 24px;
    margin-bottom: 20px;
    background-image: url(./img/icon_close.svg);
    background-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 31px 24px;
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    @include trans();
    &:hover {
        @include trans();
        opacity: 0.5;
    }
}

.modal__form {
    
    margin: 0;
    padding: 0;
}

.modal__hidden {
    display: none;
}

.modal__content h3 {

    text-transform: uppercase;
    margin-bottom: 2 * $p-basic;
}

.modal__content h2 {
    text-transform: uppercase;
    margin-bottom: 4.5 * $p-basic;

}

.modal__form__group {
    margin-bottom: 10px;
}
.modal__form__group__flex {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.modal__q {
    height: 56px;
    width: auto;
}
.modal__btn {
    width: 150px;
    background-color: #FFF;
    border: none;
    height: 56px;
    cursor: pointer;
    line-height: normal;

}

.modal__form input[type="text"],
.modal__form input[type="email"],
.modal__form input[type="tel"] {
    border-radius: 0px;
    padding: 16px 19px;
    border: none;
    color: $c-grey-dark;
    box-sizing: border-box;
    width: 100%;
}

.modal__textarea {
    border-radius: 0px;
    padding: 26px 29px;
    height: 300px;
    box-sizing: border-box;
    width: 100%;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
   color: #757575;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
}

:-ms-input-placeholder {
    /* IE 10+ */
   color: #757575;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
}

.map {
    min-height: 80vh;
    @media screen and (max-width: 768px) {
         min-height: 80vh;
    }

    img {
      display: block;
    }

}
.map > div {
  width: 100% !important;
  height: 100% !important;
}
