/*  FOOTER */
.foot__wrap {
    background-color: $c-grey-verylight;
    padding: 35px 0px 45px 0px;
    color: #FFFFFF;
}

.foot__wrap a {
    color: #FFFFFF;
    text-decoration: none;
    @include trans();
    &:hover {
        @include trans();
        color: $c-green;
    }
}

.foot__adress a {
    text-decoration: underline;
}
.foot__wrap h3 {
    margin-top: 0px;
}
.foot__wrap .container {
    display: flex;
    gap: 50px;
    @media screen and (max-width:650px) {
        flex-wrap: wrap;
        gap: 20px;
    }

}

.foot__adress {
    
    @media screen and (max-width:650px) {
        width: 100%;
    }
    @media screen and (min-width:651px) {
        flex-grow: 1;
        flex-basis: 0;
    }
}
.foot__nav {
    display: flex;
    gap: 120px;
    
    @media screen and (max-width:650px) {
        width: 100%;
    }
    @media screen and (min-width:651px) {
        flex-grow: 1;
        flex-basis: 0;
    }
    @media screen and (max-width:990px) {
        gap: 60px;
    }
    @media screen and (max-width:650px) {
        gap: 30px;
    }
}

.foot__nav li {
    display: block;
    margin-bottom: 10px;
    
}