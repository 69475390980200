/* BREADCRUMP */
.bc {
    padding-top: 33px;
    padding-bottom: 27px;
    border-bottom: 1px solid $c-green;
    margin-bottom: 70px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
    @media screen and (max-width: 650px){
        padding-top: 0px;
        padding-bottom: 13px;
        margin-bottom: 15px;
        margin-bottom: 30px;
    }   
}

.bc_noMb {
    margin-bottom: 0px !important;
}

.bc li {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-image: url('./img/arrowleftGrey.svg');
        width: 14px;
        height: 8px;
        background-size: 14px 8px;
        background-repeat: no-repeat;
        background-position: center center;
        right: -14px;
        top: 9px;
        transform: rotate(180deg);
        @media screen and (max-width: 650px){
            top: 10px;
        } 
    }
    &:last-child::before {
        display: none;
    }
}

.bc li a {
    font-size: 1rem;
    color: $c-txt;
    @include trans();
    &:hover {
        @include trans();
        color: $c-blue-light;
    }
    @media screen and (max-width: 650px){
        font-size: 0.8rem;
    }  
}

.bc li:last-child a {
    font-weight: 700;
}