/* BUTTONS */
.btn, .btn:hover, .btn:focus, .btn:active  {
    display: inline-block;
    background-color: rgb($c-green, 1);
    padding: 16px 43px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $c-black;
    border-radius: 60px;
    font-weight: 700;
    border: none;
    @include trans();
    &:hover {
        @include trans();
        background-color: rgb($c-green, 0.5);
    }
}

.btn-inv, .btn-inv:hover, .btn-inv:focus, .btn-inv:active  {
    display: inline-block;
    background-color: rgb($c-green, 0);
    border: 1px rgb($c-green, 1) solid;
    padding: 16px 43px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $c-black;
    border-radius: 60px;
    font-weight: 700;
    @include trans();
    &:hover {
        @include trans();
        background-color: rgb($c-green, 1);
    }
}

.btn-toggle, .btn-toggle:hover, .btn-toggle:focus, .btn-toggle:active  {
    display: inline-block;
    cursor: pointer;
    background-color: rgb($c-green, 0);
    border: 1px rgb($c-txt, 1) solid;
    padding: 13px 20px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $c-black;
    border-radius: 60px;
    font-weight: 700;
    @include trans();
    &:hover {
        @include trans();
        background-color: rgb($c-green, 1);
        border: 1px rgb($c-green, 1) solid;
    }
}