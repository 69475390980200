/* VARIABLES */

/* FONTS */
$inter: Inter, helvetica, arial, sans-serif;


//$panton_narrowregular: Inter, helvetica, arial, sans-serif;
//$pantonregular: Inter, helvetica, arial, sans-serif;
//$panton_semiboldregular: Inter, helvetica, arial, sans-serif;

/* COLORS */
$c-white: rgba(255,255,255,1); //#FFFFFF
$c-txt: rgba(40,45,70,1); //#282d46
$c-black: rgba(0,0,0,1); //#000000
$c-green: rgba(18,255,141); //#12FF8D
$c-blue-dark: rgba(9,107,176); //#096bb0
$c-blue-light: rgba(47,186,237); //#2FBAED



$c-grey: rgba(60,60,60,1); //#53575f
$c-grey-light: rgba(90,90,90,1); //#5a5a5a
$c-grey-verylight: rgba(51, 51, 51, 1); //#333333
$c-grey-dark: rgba(60,60,60,1); //#3c3c3c
$c-grey-back: rgba(237,236,235,1); //#EDECEB



/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1280px;



/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
