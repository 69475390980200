/* PRODUCT */




.pr__gallery__hidden {
    display: none;
}

.pr__header {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 60px;
    @media screen and (max-width: 990px){
        margin-bottom: 60px;
    }  
    @media screen and (max-width: 720px){        
        flex-wrap: wrap;
    }  
    @media screen and (max-width: 650px){
        margin-bottom: 40px;        
    }  
}
.pr__header_small {
    display: flex;
    gap: 10px;
    align-items: stretch;
    margin-bottom: 10px;
}

.news_date {
    color: $c-txt;
    margin-bottom: 10px;
    display: block;
}

.pr__txt {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: $c-txt;
    width: calc(65% - 5px);
    @media screen and (max-width: 1250px){
        font-size: 1.2rem;
        line-height: 1.8rem;
        width: calc(50% - 5px);
    }   
    @media screen and (max-width: 720px){
        width: 100%;
        margin-bottom: 10px;
    }  
}

.pr__txt h1 {
    font-size: 2.8rem;
    line-height: 2.5rem;
    color: $c-green;
    margin-bottom: 57px;
    @media screen and (max-width: 1250px){
        font-size: 2.1rem;
        line-height: 2.5rem;
        margin-bottom: 35px;
    }   
}

.pr__txt a {
    text-decoration: underline;
    color: $c-blue-dark;
    &:hover {
        color: $c-green;
    }
}

.pr__gallery {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #FAFAFA;
    position: relative;
}
.pr__gallery img {
    max-width: 100%;
}
.pr__gallery__info {
    display: inline-block;
    width: auto;
    padding: 7px 23px;
    border-radius: 60px;
    background-color: #FFFFFF;
    color: #000000;
    position: absolute;
    bottom: 35px;
    right: 60px;
    font-weight: 500;
    z-index: 1;
    cursor: pointer;
    &::before {
        position: absolute;
        content: "+";
        width: 41px;
        height: 41px;
        background-color: $c-green;
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
        right: -27px;
        top: -3px;
        display: flex;
        align-items: center;
        font-size: 2rem;
        line-height: normal;
        font-weight: 700;
        justify-content: center;
    }
    @media screen and (max-width: 720px){
        bottom: 15px;
        right: 20px;
    }  
}

.pr__art {
    margin-bottom: 32px;
    padding-bottom: 64px;
    border-bottom: 1px solid $c-green;
}

.pr__toggleBtns {
    position: relative;
    z-index: 0;
    margin-bottom: 57px;
    &::before {
        z-index: -1;
        content: "";
        position: absolute;
        bottom: 37px;
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: $c-green;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 25px;
    }  
}

.pr__toggleBtns p {
    display: inline-block;
    background-color: #FFFFFF;
    
}

.pr__toggleBtns .btn-toggle {
    margin-right: 16px;
    margin-bottom: 16px;
    @media screen and (max-width: 650px){
       font-size: 1rem;
       padding: 8px 10px;
    }  
}

.btn-toggle.active {
    background-color: rgb($c-green, 1);
    border: 1px rgb($c-green, 1) solid;
}

.pr__art__cont {
    display: none;
    font-size: 1.2rem;
    line-height: 1.8em;
    color: rgba($c-grey-verylight, 0.8);
    @media screen and (max-width: 650px){
        font-size: 1.1rem;
        line-height: 1.6rem;
    }
}


.pr__art__cont.visible {
    display: block;
}

.pr__art__cont h2, .pr__art__cont h3, .pr__art__cont h4, .pr__art__cont h5, .pr__art__cont h6 {
    color: $c-blue-light;
}

.pr__art__cont h2 {
    font-size: 1.75rem;
    line-height: 2.375rem;
    margin-bottom: 10px;
    margin-top: 0px;
    
}

.pr__art__cont__pro {
    max-width: 695px;
    background: #F2F2F2;
    padding: 23px 42px;
    display: grid;
    grid-template-columns: fit-content(320px) 1fr;
    grid-gap: 0px 30px;
    @media screen and (max-width: 650px){
        grid-template-columns: 1fr;
        padding: 15px 15px;
    }
}
.pr__art__cont__pro dt {
    color: $c-txt;
    font-weight: 600;
}

.pr__art__cont__pro dd {
    margin-left: 0px;
    color: rgba($c-grey-verylight,0.8);
    font-weight: 600;
}

.pr__art__cont__dwn {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
    @media screen and (max-width: 990px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
    @media screen and (max-width: 720px){
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
}

.pr__art__cont__dwn a {
    cursor: pointer;
    background: #F2F2F2;
    padding: 23px 42px;
    text-align: center;
    color: rgba($c-grey-verylight,0.8);
    box-shadow: 0px 4px 44px rgba(40, 45, 70, 0);
    @include trans();
    @media screen and (max-width: 650px){
        padding: 15px 15px;
    }
    &:hover {
        @include trans();
        box-shadow: 0px 4px 44px rgba(40, 45, 70, 0.13);
        span {
            @include trans();
            color: $c-blue-light;
        }
    }
}

.pr__art__cont__dwn a span {
    @include trans();
    display: block;
    margin-top: 20px;
    font-weight: 700;
    text-decoration: underline;
}

.pr__art__cont__dwn__h2 {
    padding: 30px 0px 35px 0px;
    @media screen and (max-width: 720px){
        padding: 15px 0px 20px 0px;
    }
    @media screen and (max-width: 650px){
        padding: 5px 0px 10px 0px;
    }
}
.pr__altlist {
    margin-bottom: 100px;
    @media screen and (max-width: 650px){
        margin-bottom: 40px;
    }
}
.pr__altlist h3 {
        margin: 0px 0px 32px 0px;
        background: -webkit-linear-gradient(180deg, #00bdf1 0%, #007db9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3rem;
        line-height: 3.625rem;
        @media screen and (max-width: 990px){
            font-size: 2.5rem;
            line-height: 2.8rem;
        }
        @media screen and (max-width: 650px){
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
}

.pr__altlist__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    @media screen and (max-width: 650px){
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
}

.pr__altlist__grid a {
    display: block;
    padding: 16px;
    @include trans();
    cursor: pointer;
    box-shadow: 0px 4px 44px rgba(40, 45, 70, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:hover {
        box-shadow: 0px 4px 44px rgba(40, 45, 70, 0.13);
        @include trans();
        & .btn-inv {
            @include trans();
            background-color: rgb($c-green, 1);
        }
    }
    @media screen and (max-width: 650px){
        padding: 0px;
    }
}

.pr__altlist__grid a img {
    display: block;
    max-width: 100%;
    margin-bottom: 24px;
    background: rgba(153, 158, 183, 0.28);
    @media screen and (max-width: 990px){
        margin: 0px 0px 12px 0px;
    }
}

.pr__altlist__grid a h4 {
    display: block;
    padding: 16px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 700;
    margin: 0px 0px 24px 0px;
    padding: 0;
    color: $c-txt;
    @media screen and (max-width: 990px){
        font-size: 1.6rem;
        line-height: 1.8rem;
        margin: 0px 0px 12px 0px;
    }
}

.pr__altlist__grid a p {
    display: flex;
    flex: 1;
    align-items: flex-end;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 24px;
    color: rgba($c-grey-verylight,0.8);
    @media screen and (max-width: 650px){
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

.pr__altlist__grid a .btn-inv {
    margin-bottom: 12px;
    @media screen and (max-width: 650px){
        margin-bottom: 12px;
    }
}


.prs__top {
    font-size: 1.2rem;
    line-height: 1.6em;
    color: $c-txt;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @media screen and (max-width: 1250px){
        font-size: 1.2rem;
        line-height: 1.8rem;
    }   
    @media screen and (max-width: 650px){
        margin-bottom: 10px;
        flex-wrap: wrap;
    }  
}

.prs__top h1 {
    font-size: 2.5rem;
    line-height: 2.8rem;
    color: $c-green;
    margin-bottom: 57px;
    background: -webkit-linear-gradient(180deg, rgba(0,189,241,1) 0%, rgba(0,125,185,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 650px){
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 35px;
    }   
}

.prs__top__txt {
    flex: none;
    margin-bottom: 20px;
    width: 100%;
    @media screen and (min-width: 650px){
       flex:1;
       width: auto;
       margin-bottom: 0px;
    }  
}

.prs__top__img {
    max-width: 380px;
    width: 100%;
    margin-left: 0px;
    
    @media screen and (min-width: 720px){
        margin-left: 40px;
     }
    }