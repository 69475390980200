/* HOME */
.hp__h {
    padding: 45px 0px 0px 0px;
    background-image: url('./img/hot_top_back.jpg') ;
    background-size: cover;
    background-position: bottom 0px center;
    margin-bottom: 90px;
    background-color: #FFFFFF;
    @media screen and (max-width: 1060px) {
        padding: 50px 0px 0px 0px;   
    }
    @media screen and (max-width: 990px) {
        padding: 20px 0px 0px 0px;   
    }
    @media screen and (max-width: 850px) {
        background-position: bottom 40px center; 
        margin-bottom: 45px;
    }
    @media screen and (max-width: 806px) {
        background-position: bottom 20px center; 
    }
    @media screen and (max-width: 650px) {
        background-position: bottom 50px center; 
        margin-bottom: 20px;
    }
}

.hph__container {
    display: flex;
    @media screen and (max-width: 650px) {
       flex-wrap: wrap;
    }
}
.hph__txt  {
    color: #FFFFFF;
    font-size: 1.125rem;
    line-height: 2.07rem;
    max-width: 640px;
    font-weight: 600;
    margin-bottom: 40px;
    @media screen and (max-width: 765px) {
        max-width: 60%;
    }
    @media screen and (max-width: 650px) {
        max-width: 100%;
        width: 100%;
    }
}
.hph__txt h1 {
    color: #FFFFFF;
    margin-bottom: 35px;
    @media screen and (max-width: 1060px) {
        font-size: 2.875rem;
        line-height: 3.6875rem;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 990px) {
        font-size: 2.1rem;
        line-height: 3.1rem;
        margin-bottom: 10px;
    }
}
.hph__txt p  {
    margin-bottom: 45px;
    @media screen and (max-width: 1060px) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 990px) {
        font-size: 1rem;
        margin-bottom: 10px;
    }
}
.hph__img {
    flex: 1;
    text-align: center;
    @media screen and (max-width: 650px) {
        max-width: 100%;
        width: 100%;
        flex: none;
    }
}
.hph__imgImg {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.hpp {
    margin-bottom: 100px;
    @media screen and (max-width: 650px) {
        margin-bottom: 30px;
    }
}
.hpp h2 {
    background: -webkit-linear-gradient(180deg, rgba(0,189,241,1) 0%, rgba(0,125,185,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.875rem;
    line-height: 4.6875rem;
    text-align: center;
    margin-top: 0px;
    @media screen and (max-width: 990px) {
        font-size: 2.875rem;
        line-height: 3.6875rem;
    }
    @media screen and (max-width: 990px) {
        font-size: 1.875rem;
        line-height: 2.6875rem;
    }
}


.hpp__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
    gap: 24px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 840px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
    }
  }


  .hpp__grid h3 {
    font-size: 1.8125rem;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 0px;
    @media screen and (max-width: 650px) {
        font-size: 1.4rem;
    }
  }
  .hpp__grid  a {
    background-color: #FAFAFA;
    
    background-position: right bottom;
    background-repeat: no-repeat;
    padding: 34px 30px 180px 30px;
    display: block;
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: rgba($c-grey-verylight,0.8);
    box-shadow: 0px 2px 25px -1px rgba(0,0,0,0);
    @include trans();
    background-size: calc(50% + 15px);
    cursor: pointer;
    &:hover {
        @include trans();
        box-shadow: 0px 2px 25px -1px rgba(0,0,0,0.15);
    }
    @media screen and (max-width: 650px) {
        font-size: 1.125rem;
        line-height: 1.6rem;
    }
  }

  .hpv {
    @extend .hpp;
  }

  .hpv h2 {
    background: -webkit-linear-gradient(180deg, rgba(0,189,241,1) 0%, rgba(0,125,185,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.875rem;
    line-height: 3.6875rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
    @media screen and (max-width: 990px) {
        font-size: 1.875rem;
        line-height: 2.2rem;
    }
    @media screen and (max-width: 650px) {
        font-size: 1.875rem;
        line-height: 2.6875rem;
    }
}

.hpv p {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.6rem;
    display: block;
    margin: 0px auto 30px auto;
    color: rgba($c-grey-verylight,0.8);
    width: 100%;
    max-width: 540px;
    margin-bottom: 65px;
    @media screen and (max-width: 650px) {
        margin-bottom: 35px;
    }
}

.hpv ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: flex;
    gap: 26px;
    @media screen and (max-width:990px) {
        flex-wrap: wrap;
    }
}    
.hpv li {
    
    border-radius: 50%;
    text-align: center;
    aspect-ratio: 1 / 1;
    background: #FAFAFA;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width:991px) {
        flex-grow: 1;
        flex-basis: 0;
    }    
    @media screen and (max-width:990px) {
        width: calc(50% - 26px);
    }
    @media screen and (max-width:500px) {
        width: calc(100%);
    }
    
}
.hpv li img {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
}

.hpv li span {
    display: block;
    max-width: 204px;
    color: $c-grey-verylight;
    text-align: center;
    font-weight: 700;
    font-size: 1.8125rem;
    line-height: 2.1875rem;
    
}

.hpv__btns {
    margin-top: 60px;
    text-align: center;
    @media screen and (max-width:650px) {
        margin-top: 30px;
    }
}

.hpn {
    padding: 63px 0px 0px 0px;
    background-image: url('./img/hpn_back.jpg') ;
    background-size: 100% 60%;
    background-position: top center;
    background-color: #FFFFFF;
    margin-bottom: 100px;
    @media screen and (max-width:650px) {
        padding: 30px 0px 0px 0px;
        margin-bottom: 50px;
    }
}

.hpn__txt {
    color: #FFFFFF;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 32px;
    max-width: 940px;
    @media screen and (max-width:769px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    @media screen and (max-width:650px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}
.hpn .btn {
    margin-bottom: 32px;
}

.hpn__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 30px;
    @media screen and (max-width:650px) {
        flex-wrap: wrap;
    }
}
.hpn__list li {
    display: block;
    @media screen and (max-width:650px) {
        width: 100%;
    }
}
.hpn__list a {
    color: $c-txt;
    display: block;
    @include trans();
    @media screen and (max-width:650px) {
        width: 100%;
    }
    &:hover {
        @include trans();
        color: $c-blue-light;
    }
}
.hpn__list img {
    display: block;
    max-width: 100%;
    margin-bottom: 32px;
    @media screen and (max-width:650px) {
        width: 100%;
        margin-bottom: 15px;
    }
}

.hpn__list li span {
    font-weight: 700;
    color: 1.125rem;
    line-height: 1.5rem;
    display: block;
    max-width: 100%;
    margin-bottom: 32px;
    @media screen and (max-width:650px) {
        width: 100%;
        margin-bottom: 15px;
        color: 1rem;
        line-height: 1.3rem;
    }
}

.hpn__list li a p {
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 2.625rem;
    display: block;
    max-width: 100%;
    @media screen and (max-width:990px) {
        width: 100%;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    @media screen and (max-width:650px) {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
}