/* _solutionsStrip.scss */

.ss__wrap {
    background-color: $c-txt;
    background-image: url(./img/solutions_back.png);
    background-position:  35%  center;
    background-size: auto 100%;
    padding: 72px 0px 100px 0px;
    @media screen and (max-width: 640px){
        padding: 35px 0px 40px 0px;
    }
}

.ss__wrap h2 {
    text-align: center;
    color: $c-green;
    font-size: 2.875rem;
    line-height: 3.5rem;
    margin: 0px 0px 54px 0px;
    @media screen and (max-width: 640px){
        font-size: 1.875rem;
        line-height: 2.5rem;
        margin: 0px 0px 25px 0px;
    }
}

.ss_sliders  {
    display: flex;
    margin-bottom: 85px;
    @media screen and (max-width: 640px){
        margin-bottom: 40px; 
    }
}
.ss_slider {
    display: flex !important;
    align-items: center;
    @media screen and (max-width: 640px){
        display: block !important;
    }
}
.ss_slider_img {
    width: 50%;
    @media screen and (max-width: 1130px){
        width: 30%;  
    }
    @media screen and (max-width: 640px){
        width: 100%;  
    }
}
.ss_slider_txt {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 141px;
    padding-right: 141px;
    font-size: 2.125rem;
    line-height: 2.625rem;
    color: #FFF;
    text-decoration: "none";
    color: $c-green;
    @media screen and (max-width: 1130px){
        font-size: 1.8rem;
        line-height: 2.1rem;
        padding-left: 80px;
        padding-right: 80px;
    }
    @media screen and (max-width: 640px){
        padding-left: 0px;
        padding-right: 0px;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
}
.ss_slider_txt h3 {
    color: $c-green;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 24px;

}    
.ss__btns {
    text-align: center;
}

.ss__wrap .tns-outer {
    position: relative;
}

.ss__wrap .tns-controls {
    position: absolute;
    top: 35%;    
    z-index: 200;
    width: 100%;
    @media screen and (max-width:  640px){
        top: 20%;
    }
}

.ss__wrap .tns-controls button {
    display: block;
    width: 51px;
    height: 51px;
    background-color: rgba($c-blue-dark,0);
    background-image: url(./img/arrowright.svg);
    background-size: 100% 100%;
    border: none;
    position: absolute;
    right: 0px;
    @media screen and (max-width: 1130px){
        width: 36px;
        height: 36px;
    }
    @media screen and (max-width:  640px){
        width: 51px;
        height: 51px;
        background-color: rgba($c-blue-dark,0.5);
        border-radius: 50%;
        right: 10px;
    }

}

.ss__wrap .tns-controls button:first-child {
    right: 42.5%;
    transform: rotate(180deg);
    @media screen and (max-width: 1130px){
        right: 64%;
    }
    @media screen and (max-width: 990px){
        right: 62%;
    }
    @media screen and (max-width: 650px){
        right: inherit;
        left: 10px;
    }
}