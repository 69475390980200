/* HEADERS */
.header__mainMenu {
  background: rgb(5,86,146);
	background: linear-gradient(90deg, rgba(5,86,146,1) 0%, rgba(34,141,186,1) 34%, rgba(42,151,195,1) 100%);
	margin-bottom: 13px;
}
$menuToggle: 1060px;
$menuToggleMin: 1059px;
$menuToggleMax: 1061px;
.containerMenu {	

	@media screen and (max-width: $menuToggle){
		padding-left: 0px !important;
		padding-right: 0px !important;
		border-bottom: 1px solid $c-blue-dark;
	}
}


// Generate rules to indent sub menus text
//
// We'll use left border to avoid messing with the padding.

@mixin sm-simple__sub-items-indentation($amount, $chainable: 'ul ', $level: 4, $chain: '') {
	@for $i from 1 through $level {
		$chain: $chain + $chainable;
		#{$chain} a,
		#{$chain} a:hover,
		#{$chain} a:focus,
		#{$chain} a:active {
			border-left: ($amount * ($i + 1)) solid transparent;
		}
	}
}

// This file is best viewed with Tab size 4 code indentation


// -----------------------------------------------------------------------------------------------------------------
// 1. Theme Quick Settings (Variables)
// (for further control, you will need to dig into the actual CSS in 2.)
// -----------------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------
// :: 1.1. Colors
// ----------------------------------------------------------

$sm-simple__white:										#fff !default;
$sm-simple__gray:										darken($sm-simple__white, 6.5%) !default;
$sm-simple__gray-dark:									darken($sm-simple__white, 26.5%) !default;
$sm-simple__gray-darker:								$sm-simple__white !default;

$sm-simple__box-shadow:									rgba(0, 0, 0, 0.25) !default;


// ----------------------------------------------------------
// :: 1.2. Breakpoints
// ----------------------------------------------------------

$sm-simple__desktop-vp:									$menuToggle !default;		// switch from collapsible to desktop


// ----------------------------------------------------------
// :: 1.3. Typography
// ----------------------------------------------------------

$sm-simple__font-family:								$inter;
$sm-simple__font-size-base:								15px !default;
$sm-simple__font-size-small:							14px !default;
$sm-simple__line-height:								17px !default;


// ----------------------------------------------------------
// :: 1.4. Borders
// ----------------------------------------------------------

$sm-simple__border-width:								0px !default;


// ----------------------------------------------------------
// :: 1.5. Collapsible main menu
// ----------------------------------------------------------

// Menu box
$sm-simple__collapsible-bg:								$sm-simple__white !default;
$sm-simple__collapsible-border-color:					$sm-simple__gray-dark !default;
$sm-simple__collapsible-box-shadow:						10px 14px 50px $sm-simple__box-shadow !default;

// Items
$sm-simple__collapsible-item-color:						$c-txt !default;
$sm-simple__collapsible-item-current-color:				$c-txt !default;
$sm-simple__collapsible-item-current-bg:				$c-green !default;
$sm-simple__collapsible-item-disabled-color:			darken($sm-simple__white, 20%) !default;
$sm-simple__collapsible-item-padding-vertical:			13px !default;
$sm-simple__collapsible-item-padding-horizontal:		20px !default;

// Items separators
$sm-simple__collapsible-separators-color:				rgba(0, 0, 0, 0.05) !default;

// Toggle button (sub menu indicators)
$sm-simple__collapsible-toggle-bg:						rgba(0, 0, 0, 0.08) !default;


// ----------------------------------------------------------
// :: 1.6. Collapsible sub menus
// ----------------------------------------------------------

// Menu box
$sm-simple__collapsible-sub-bg:							rgba(darken($sm-simple__collapsible-bg, 30%), 0.1) !default;

// Items text indentation for deeper levels
$sm-simple__collapsible-sub-item-indentation:			8px !default;


// ----------------------------------------------------------
// :: 1.7. Desktop main menu and sub menus
// ----------------------------------------------------------

// Menu box
$sm-simple__desktop-bg:									transparent !default;

// Items
$sm-simple__desktop-item-color:							$sm-simple__gray-darker !default;
$sm-simple__desktop-item-hover-bg:						$c-green !default;
$sm-simple__desktop-item-hover-color:						$c-txt !default;
$sm-simple__desktop-item-current-color:					$c-txt !default;
$sm-simple__desktop-item-current-bg:					$c-green !default;
$sm-simple__desktop-item-disabled-color:				darken($sm-simple__white, 20%) !default;
$sm-simple__desktop-item-padding-vertical:				23px !default;
$sm-simple__desktop-item-padding-horizontal:			15px !default;

// Items separators
$sm-simple__desktop-separators-size:					0px !default;
$sm-simple__desktop-separators-color:					$sm-simple__gray !default;

// Sub menu indicators
$sm-simple__desktop-arrow-spacing:						4px !default;


// -----------------------------------------------------------------------------------------------------------------
// 2. Theme CSS
// -----------------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------
// :: 2.1. Collapsible mode (mobile first)
// ----------------------------------------------------------

// calc item height and sub menus toggle button size
$sm-simple__item-height: $sm-simple__line-height + $sm-simple__collapsible-item-padding-vertical * 2;
// set toggle button size to 80% of item height
$sm-simple__toggle-size: floor($sm-simple__item-height * 0.8);
$sm-simple__toggle-spacing: floor($sm-simple__item-height * 0.1);

// Main menu box
.sm-simple {
	border: $sm-simple__border-width solid $sm-simple__collapsible-border-color;
	background: $sm-simple__collapsible-bg;
	

	// Main menu items
	a {
		&,
		&:hover,
		&:focus,
		&:active {
			padding: $sm-simple__collapsible-item-padding-vertical $sm-simple__collapsible-item-padding-horizontal;
			/* make room for the toggle button (sub indicator) */
			padding-right: $sm-simple__collapsible-item-padding-horizontal + $sm-simple__toggle-size + $sm-simple__toggle-spacing;
			color: $sm-simple__collapsible-item-color;
			font-family: $sm-simple__font-family;
			font-size: $sm-simple__font-size-base;
			font-weight: normal;
			line-height: $sm-simple__line-height;
			text-decoration: none;
			font-size: 14px;
		}
		&.current {
			background: $sm-simple__collapsible-item-current-bg;
			color: $sm-simple__collapsible-item-current-color;
		}
		&.highlighted {
			background: $sm-simple__collapsible-item-current-bg;
			color: $sm-simple__collapsible-item-current-color;
		}
		&.disabled {
			color: $sm-simple__collapsible-item-disabled-color;
		}

		// Toggle buttons (sub menu indicators)
		.sub-arrow {
			position: absolute;
			top: 50%;
			margin-top: -(ceil($sm-simple__toggle-size / 2));
			left: auto;
			right: $sm-simple__toggle-spacing;
			width: $sm-simple__toggle-size;
			height: $sm-simple__toggle-size;
			overflow: hidden;
			font: bold #{$sm-simple__font-size-small}/#{$sm-simple__toggle-size} monospace !important;
			text-align: center;
			text-shadow: none;
			background: $sm-simple__collapsible-toggle-bg;
		}
		.sub-arrow::before {
			content: '+';
		}
		&.highlighted .sub-arrow::before {
			content: '-';
		}
	}

	// Main menu items separators
	li {
		border-top: 1px solid $sm-simple__collapsible-separators-color;
	}
        > li:first-child {
		border-top: 0;
	}

	// Sub menus box
	ul {
		background: $sm-simple__collapsible-sub-bg;

		// Sub menus items
		a {
			&,
			&:hover,
			&:focus,
			&:active {
				font-size: $sm-simple__font-size-small;
				// add indentation for sub menus text
				border-left: $sm-simple__collapsible-sub-item-indentation solid transparent;
			}
		}

		// Add indentation for sub menus text for deeper levels
		@include sm-simple__sub-items-indentation($sm-simple__collapsible-sub-item-indentation);
	}
}


// ----------------------------------------------------------
// :: 2.2. Desktop mode
// ----------------------------------------------------------

@media (min-width: $sm-simple__desktop-vp) {

	/* Switch to desktop layout
	-----------------------------------------------
	   These transform the menu tree from
	   collapsible to desktop (navbar + dropdowns)
	-----------------------------------------------*/
	/* start... (it's not recommended editing these rules) */
	.sm-simple ul{position:absolute;width:12em;}
	.sm-simple li{float:left;}
	.sm-simple.sm-rtl li{float:right;}
	.sm-simple ul li,.sm-simple.sm-rtl ul li,.sm-simple.sm-vertical li{float:none;}
	.sm-simple a{white-space:nowrap;}
	.sm-simple ul a,.sm-simple.sm-vertical a{white-space:normal;}
	.sm-simple .sm-nowrap > li > a,.sm-simple .sm-nowrap > li > :not(ul) a{white-space:nowrap;}
	/* ...end */

	// Main menu box
	.sm-simple {
		background: $sm-simple__desktop-bg;

		// Main menu items
		a {
			&,
			&:hover,
			&:focus,
			&:active,
			&.highlighted {
				padding: $sm-simple__desktop-item-padding-vertical $sm-simple__desktop-item-padding-horizontal;
				color: $sm-simple__desktop-item-color;
        font-weight: 600;
			}

			&:hover,
			&:focus,
			&:active,
			&.highlighted {
        color: $sm-simple__desktop-item-hover-color;
				background: $sm-simple__desktop-item-hover-bg;
			}

			&.current {
				background: $sm-simple__desktop-item-current-bg;
				color: $sm-simple__desktop-item-current-color;
			}

			&.disabled {
				background: $sm-simple__desktop-bg;
				color: $sm-simple__desktop-item-disabled-color;
			}

			// Make room for the sub arrows
			&.has-submenu {
				padding-right: $sm-simple__desktop-item-padding-horizontal + 8px + $sm-simple__desktop-arrow-spacing;
			}

			// Sub menu indicators
			.sub-arrow {
				top: 50%;
				margin-top: -8px;
				right: $sm-simple__desktop-item-padding-horizontal;
				width: 8px;
				height: 16px;
				font: #{$sm-simple__font-size-small}/16px monospace !important;
				background: transparent;
			}
			// reset mobile first style
			&.highlighted .sub-arrow::before {
				content: '+';
			}
		}

		// Main menu items separators
		> li {
			border-top: 0;
			border-left: $sm-simple__desktop-separators-size solid $sm-simple__desktop-separators-color;

			&:first-child {
				border-left: 0;
			}
		}

		// Sub menus box
		ul {
			border: $sm-simple__border-width solid $sm-simple__collapsible-border-color;
			background: $sm-simple__desktop-bg;
			box-shadow: $sm-simple__collapsible-box-shadow;

			// Sub menus items
			a {
				border: 0 !important;

				// No need for additional room for the sub arrows
				&.has-submenu {
					padding-right: $sm-simple__desktop-item-padding-horizontal;
				}

				// Sub menu indicators
				.sub-arrow {
					//right: $sm-simple__desktop-item-padding-horizontal - 8px - $sm-simple__desktop-arrow-spacing;
					right: 10px;
				}
			}

			// Sub menus items separators
			> li {
				border-left: 0;
				border-top: $sm-simple__desktop-separators-size solid $sm-simple__desktop-separators-color;

				&:first-child {
					border-top: 0;
				}
			}
		}

		// Scrolling arrows containers for tall sub menus - test sub menu: "Sub test" -> "more..." in the default download package
		.scroll-up,
		.scroll-down {
			position: absolute;
			display: none;
			visibility: hidden;
			overflow: hidden;
			background: $sm-simple__desktop-bg;
			height: 20px;
			// width and position will be set automatically by the script
		}
		.scroll-up-arrow,
		.scroll-down-arrow {
			position: absolute;
			top: -2px;
			left: 50%;
			margin-left: -8px;
			// we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: 8px; // tweak size of the arrow
			border-style: dashed dashed solid dashed;
			border-color: transparent transparent $sm-simple__desktop-item-color transparent;
		}
		.scroll-down-arrow {
			top: 6px;
			border-style: solid dashed dashed dashed;
			border-color: $sm-simple__desktop-item-color transparent transparent transparent;
		}


		// Rigth-to-left

		// Main menu box
		&.sm-rtl {

			// Main menu items
			a {

				// Make room for the sub arrows
				&.has-submenu {
					padding-right: $sm-simple__desktop-item-padding-horizontal;
					padding-left: $sm-simple__desktop-item-padding-horizontal + 8px + $sm-simple__desktop-arrow-spacing;
				}

				// Sub menu indicators
				.sub-arrow {
					left: $sm-simple__desktop-item-padding-horizontal;
					right: auto;
				}
			}

			// Vertical main menu items
			&.sm-vertical {
				a {

					// No need for additional room for the sub arrows
					&.has-submenu {
						padding: $sm-simple__desktop-item-padding-vertical $sm-simple__desktop-item-padding-horizontal;
					}

					// Sub menu indicators
					.sub-arrow {
						left: auto;
						right: $sm-simple__desktop-item-padding-horizontal - 8px - $sm-simple__desktop-arrow-spacing;
					}
				}
			}

			// Main menu items separators
			> li {
				&:first-child {
					border-left: $sm-simple__desktop-separators-size solid $sm-simple__desktop-separators-color;
				}
				&:last-child {
					border-left: 0;
				}
			}

			// Sub menus box
			ul {
				a {

					// No need for additional room for the sub arrows
					&.has-submenu {
						padding: $sm-simple__desktop-item-padding-vertical $sm-simple__desktop-item-padding-horizontal;
					}

					// Sub menu indicators
					.sub-arrow {
						left: auto;
						right: $sm-simple__desktop-item-padding-horizontal - 8px - $sm-simple__desktop-arrow-spacing;
					}
				}
			}
		}


		// Vertical main menu

		// Main menu box
		&.sm-vertical {

			// Main menu items
			a {

				// Sub menu indicators
				.sub-arrow {
					left: $sm-simple__desktop-item-padding-horizontal - 8px - $sm-simple__desktop-arrow-spacing;
					right: auto;
				}
			}

			// Main menu items separators
			li {
				border-left: 0;
				border-top: $sm-simple__desktop-separators-size solid $sm-simple__desktop-separators-color;
			}
			> li:first-child {
				border-top: 0;
			}
		}
	}
}

@media (max-width: 1320px) {


	// Main menu box
	.sm-simple {

		// Main menu items
		a {
			&,
			&:hover,
			&:focus,
			&:active,
			&.highlighted {
				padding: 23px 10px;
				font-size: 12px;
			}
		}
	}
	.sm-simple ul a, .sm-simple ul a:hover, .sm-simple ul a:focus, .sm-simple ul a:active {
		font-size: 12px;
		padding: 23px 10px;
	}

}
@media (max-width: 1060px) {


	// Main menu box
	.sm-simple {

		// Main menu items
		a {
			&,
			&:hover,
			&:focus,
			&:active,
			&.highlighted {
				font-size: 14px !important;
			}
		}
	}
	.sm-simple ul a, .sm-simple ul a:hover, .sm-simple ul a:focus, .sm-simple ul a:active {
		font-size: 14px !important;
	}

}
.sm-simple ul {
  background-color: #fff;
  box-shadow: $sm-simple__collapsible-box-shadow;
}
.sm-simple ul a {
  color:  $c-txt;
  
}


.sm-simple ul a.has-submenu{
  padding-right: 30px;
}

.header__mainMenu__MobileOnly {
	display: none !important;
	@media screen and (max-width: $menuToggleMin){
		display: block !important;
	}
}

.header__mainMenu__MobileOnlyLabel {
	padding: 15px 10px !important;
	background-color: $c-blue-dark;
	color: #FFFFFF;
}

//toggle
.main-menu-btn {
	position: relative;
	display: inline-block;
	width: 28px;
	height: 28px;
	text-indent: 28px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	
	margin-left: 15px;
	margin-top: 7px;	
	@media (min-width: 650px) {
		margin-left: 19px;
	}	
	@media (min-width: 767px) {
		margin-left: 28px;
	}	
	@media (min-width: 990px) {
		margin-left: 39px;
	}	
	@media (min-width: 1025px) {
		margin-left: 51px;
	}	
  }
  /* hamburger icon */
  .main-menu-btn-icon, .main-menu-btn-icon:before, .main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #FFF;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
  }
  .main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0;
  }
  .main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0;
  }
  /* x icon */
  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent;
  }
  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
  }
  #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
  }
  /* hide menu state checkbox (keep it visible to screen readers) */
  #main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px,1px,1px,1px);
  }
  /* hide the menu in mobile view */
  #main-menu-state:not(:checked) ~ #main-menu {
	display: none;
  }
  #main-menu-state:checked ~ #main-menu {
	display: block;
  }
  @media (min-width: $menuToggle) {
	/* hide the button in desktop view */
	.main-menu-btn {
	  position: absolute;
	  top: -99999px;
	}
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
	  display: block;
	}
  }