/* HEADERS */

$menuToggle: 1060px;


.header__topMenu {
    display: flex;
    padding: 52px 0px 24px 0px;
    align-items: center;
    @media screen and (max-width: $menuToggle) {
      padding: 24px 0px 24px 0px;
    }
    @media screen and (max-width: 650px) {
        padding: 16px 0px 16px 0px;
    }
}
.header__topMenu__logo {
    @media screen and (max-width: 650px) {
        width: 190px;
        height: auto;
    }
}

.header__topMenu__right {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header__langMenu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-type: none;
    display: inline-block;
}

.header__langMenu__img {
    width: 25px;
    height: auto;
}


/* Hide my submenus by default */
.header__langMenu ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
}

/* Position submenus relative to parent list item */
.header__langMenu li {
  position: relative;
}

/* Look, Ma! No onclick handler! */
.header__langMenu li:hover > ul {
  display: block;
}

/* Modern Browsers only */
.header__langMenu li:focus-within > ul {
  display: block;
}


.header__langMenu a {
  display: block;
  padding: 0;
  white-space: nowrap;
}

.header__langMenu a:hover {
  text-decoration: none;
}

.header__secondMenu {
  margin: 0px 0px 0px 50px;
  padding: 0;
  list-style-type: none;
  list-style-type: none;
  display: flex;  
  align-items: center;
  justify-content: center;
  width: auto;
  gap: 0px 24px;
  @media screen and (max-width: $menuToggle) {
    display: none;
  }
}

.header__secondMenu a {
  font-weight: 600;
  color: $c-txt;
  position: relative;
  &::before {
    @include trans();
    content: "";
    position: absolute;
    left: 0px;
    bottom: -5px;
    width: 0%;
    height: 3px;
    background-color: $c-txt;  
  }
}
.header__secondMenu li.active a {
  &::before {
    width: 100%; 
  }
}
.header__secondMenu a:hover {
  &::before {
    @include trans(); 
    width: 100% !important; 
  }
}

.header__secondMenu li.last a {
  background-color: rgba($c-green, 1);
  padding: 8px 20px 10px 20px;
  border-radius: 65px;
  @include trans(); 
  &::before {
    display: none;
  }
  &:hover {
    @include trans(); 
    background-color: rgba($c-green, 0.2);
  }
}