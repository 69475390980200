/*  BLOG */

.blog .bc {
    margin-bottom: 0px !important;
    border-bottom: none;
}

.blog__header {
    background-color: $c-txt;
    color: $c-white;
    padding-top: 20px;
    margin-bottom: 40px;
    @media screen and (min-width: 650px){
        padding-top: 70px;
        margin-bottom: 60px;
    }  
    @media screen and (min-width: 768px){
        padding-top: 110px;
        margin-bottom: 80px;
    } 
}
.blog__header h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: $c-green;
    margin-bottom: 57px;
    @media screen and (max-width: 1250px){
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 35px;
    }   
}
.blog__date {
    color: $c-green;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: normal;
    margin-bottom: 25px;
    display: block;
}

.blog__contentHeader {
    color: $c-white;
    font-size: 2.125rem;
    line-height: 2.625rem;
    max-width: 950px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px){
        font-size: 1.8rem;
        line-height: 2.2rem;
        
    } 
    @media screen and (max-width: 650px){
        font-size: 1.3125rem;
        line-height: 1.8rem;
        margin-bottom: 20px;
    } 
}

.bolg__topImg {
    position: relative;
}

.bolg__topImg img {
    display: block;
    margin: 0px auto;
    max-width: 100%;
}

.bolg__topImg {
    position: relative;
    z-index: 1;
}

.bolg__topImg::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 60%;
    background-color: $c-white;
    bottom: 0px;
    left: 0px;
    z-index: -1;
}

.blog__contentInner {
    padding-bottom: 40px;
    border-bottom: 1px $c-green solid;
    margin-bottom: 40px;
    @media screen and (min-width: 650px){
        padding-bottom: 40px;
        margin-bottom: 40px;
    } 
}

.blog__content {
    font-size: 1.125rem;
    line-height: 1.8rem;
    color: $c-grey-verylight;
    @media screen and (min-width: 650px){
        font-size: 1.375rem;
    line-height: 2.375rem;
    } 
}

.blog__content p {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.blog__content h2 {
    color: $c-green;
    font-size: 1.25rem;
    line-height: 1.9rem;
    margin-bottom: 10px;
    font-weight: 700;
    @media screen and (min-width: 650px){
        font-size: 1.75rem;
        line-height: 2.375rem;
    } 
}

.blog__content h3, .blog__content h4, .blog__content h5, .blog__content h6 {
    color: $c-green;
    font-size: 1.125rem;
    line-height: 1.8rem;
    margin-bottom: 10px;
    font-weight: 700;
    @media screen and (min-width: 650px){
        font-size: 1.375rem;
        line-height: 2.375rem;
    } 
}

.blog__content img {
    max-width: 100%;
    margin: 0px auto 20px auto;
    display: block;
    padding-top: 0px;
    @media screen and (min-width: 650px){
        max-width: 80%;
        margin: 0px auto 40px auto;
        padding-top: 20px;
    } 

}

.blog__gallery {
    display: grid;    
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
    @media screen and (min-width: 650px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;       
    }
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 24px;
    }
    padding-bottom: 40px;
    border-bottom: 1px $c-green solid;
    margin-bottom: 40px;
    @media screen and (min-width: 650px){
        padding-bottom: 40px;
        margin-bottom: 40px;
    } 
}

.blog__gallery a {
    display: block;
}

.blog__gallery img {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.blog__gallery__wrap h3 {
    color: $c-green;
    font-size: 1.125rem;
    line-height: 1.8rem;
    margin-bottom: 10px;
    font-weight: 700;
    @media screen and (min-width: 650px){
        font-size: 1.375rem;
        line-height: 2.375rem;
    } 
}