/* contact */
.contact__main {
    margin-bottom: 40px;
    @media screen and (min-width: 650px) {
        margin-bottom: 65px;
    }
    @media screen and (min-width: 768px) {
        margin-bottom: 96px;
    }
}

.contact__main h1 {
    color: $c-green;
    margin-bottom: 64px;
    background: -webkit-linear-gradient(180deg, rgba(0,189,241,1) 0%, rgba(0,125,185,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 768px){
        margin-bottom: 30px;
    }  
    @media screen and (max-width: 650px){
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 0px;
    }   
     
}

.contact__main p {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.contact__main a {
    color:$c-grey-verylight;
    text-decoration: underline;
}

.contact__content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    @media screen and (min-width: 650px) {
        gap: 40px;
        flex-wrap: nowrap;
    }
    @media screen and (min-width: 768px) {
        gap: 150px;
    }
}

.contact__form {
    flex: none;
    width: 100%;
    order: 2;
    @media screen and (min-width: 650px) {
        order: 1;
        flex: 1;
    }
}

.contact__adress {
    flex: none;
    width: 100%;
    order: 1;
    @media screen and (min-width: 650px) {
        flex: 1;
        order: 2;
    }
}

.contact__content h2 {
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 5px;
    @media screen and (min-width: 650px) {
        font-size: 2.125rem;
        margin-bottom: 20px;
    }
}

.contact__content__check {
    margin-bottom: 20px;
    @media screen and (min-width: 650px) {
        margin-bottom: 60px;
    }
}